import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tap from "@tapfiliate/tapfiliate-js";
import axios from "axios";
import Chip from "components/General/formComponents/chip/Chip";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";
import Checkbox from "components/formComponents/checkbox/Checkbox";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";
// Images
import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
import FoodIconTwo from "assets/images/App/aok.svg";
import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";

import * as CoachActions from "redux/coach/coach.actions";
import * as G from "styles/General.Styles";
import * as C from "./LeadsSurvey.style";

import { parseApiErrors } from "configs/utils";

const LeadsSurveyForm = ({ isInPagePlacement }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [error, setError] = useState(null);

  const refParam = params.get("ref");

  const saveTrainerLeads = useSelector((state) => state.coach.saveTrainerLeads);
  const isTrainerLeadSaving = useSelector(
    (state) => state.coach.isTrainerLeadSaving
  );

  const trainerProfileData = useSelector((state) => state.coach.coach);
  const [CurrentStep, setCurrentStep] = useState(1);

  const [SelectedStepOne, setSelectedStepOne] = useState(null);
  const [SelectedStepTwo, setSelectedStepTwo] = useState(null);
  const [SelectedStepThree, setSelectedStepThree] = useState(null);
  const [ipAddress, setIpAddress] = useState("");
  const [FullName, setFullName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Message, setMessage] = useState("");
  const [IsSending, setIsSending] = useState(false);
  const [EmailHasSent, setEmailHasSent] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const STEP_ONE = [
    { id: 1, title: "Gewichtsabnahme" },
    { id: 2, title: "Muskelaufbau" },
    { id: 3, title: "Gesunder Lebensstil" },
  ];

  const STEP_TWO = [
    { id: 1, title: "Männlich" },
    { id: 2, title: "Weiblich" },
    { id: 3, title: "Keine Angabe" },
  ];

  const STEP_THREE = [
    { id: 1, title: "18-21 Jahre" },
    { id: 2, title: "22-25 Jahre" },
    { id: 3, title: "26-35 Jahre" },
    { id: 4, title: "36-45 Jahre" },
    { id: 5, title: "45+ Jahre" },
  ];

  useEffect(() => {
    console.log("refParam", refParam);

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();

    // Tap.customer(Email);
  }, []);

  const onItemSelect = (step, selectedItem) => {
    if (step === 1) {
      setSelectedStepOne(selectedItem);
    }

    if (step === 2) {
      setSelectedStepTwo(selectedItem);
    }

    if (step === 3) {
      setSelectedStepThree(selectedItem);
    }
  };

  const onNext = (step) => {
    switch (step) {
      case 1:
        if (SelectedStepOne !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 2:
        if (SelectedStepTwo !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 3:
        if (SelectedStepThree !== null) setCurrentStep(CurrentStep + 1);
        break;
      case 4:
        if (
          Message &&
          FirstName &&
          LastName &&
          PhoneNumber &&
          Email &&
          isCheckboxChecked
        ) {
          sendEmail();
        }
        break;
      default:
        break;
    }
  };

  const onPrev = (step) => {
    setCurrentStep(step - 1);
  };

  const sendEmail = () => {
    const payload = {
      connected_trainer: trainerProfileData?.trainerID,
      prename: FirstName,
      surename: LastName,
      mobilenumber: PhoneNumber,
      email: Email,
      lead_source: "Coach Webseite",
      lead_received: getCurrentDateFormatted(),
      lead_contacted: "",
      lead_status: "received",
      lead_goal: SelectedStepOne?.title,
      lead_gender: SelectedStepTwo?.title,
      lead_age: SelectedStepThree?.title,
      lead_proposed_date: "",
      lead_informations: Message,
    };

    dispatch(
      CoachActions.saveTrainerLeadRequest({
        payload,
        callback: (res) => {
          console.log("got response : ", res);

          if (res instanceof Error) {
            const parsedErrors = parseApiErrors(res.response.data);
            let isEmailErrorMessage = parsedErrors.find(
              (error) => error.fieldKey === "email"
            );
            let errorMessage = isEmailErrorMessage
              ? "Deine E-Mail Adresse wurde bereits eingetragen. Bitte kontaktiere mich persönlich per E-Mail."
              : "An error accured, please try again.";
            return setError(errorMessage);
          }

          console.log("saveTrainerLeadRequest", res);
          setCurrentStep(CurrentStep + 1);
          createCustomerOnTapifiliate();
        },
      })
    );
  };

  const resetForm = () => {
    setPhoneNumber("");
    setEmail("");
    setMessage("");
    setFullName("");
    setFirstName("");
    setLastName("");
  };

  const createCustomerOnTapifiliate = async () => {
    const payload = {
      customer_id: Email,
      referral_code: refParam || "ogmwmdy",
      meta_data: {
        fullname_coach:
          trainerProfileData?.prename + " " + trainerProfileData?.surname,
        email_coach: trainerProfileData?.email,
        vorname_klient: FirstName,
        nachname_klient: LastName,
        email_klient: Email,
        FranchiseID: trainerProfileData?.FranchiseID,
      },
      user_agent: navigator.userAgent,
      ip: ipAddress,
      landing_page: window.localStorage.href,
    };

    try {
      const response = await axios.post(
        "https://api.tapfiliate.com/1.6/customers/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Api-Key": "3acc178a1eff97b14cc1b12c00222e070a3a1b91",
          },
        }
      );
      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getCurrentDateFormatted = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <C.SurveyForm className="lead-form">
      <C.SurveyFormCard isInPagePlacement>
        <div className="inner-box">
          {CurrentStep === 1 && (
            <>
              <C.SurveyFormTitle>
                <h5 className="font-medium">Wie lautet deine Zielsetzung?</h5>
                {isInPagePlacement ? (
                  <p className="text-base">
                    Beantworte mir 3 kurze Fragen, sodass ich mich auf unser
                    Gespräch gut vorbereiten kann.
                  </p>
                ) : (
                  ""
                )}
              </C.SurveyFormTitle>
              {STEP_ONE.map((item, i) => (
                <C.FormItem
                  key={i}
                  active={SelectedStepOne?.id === item?.id}
                  onClick={() => onItemSelect(1, item)}
                >
                  {item?.title}
                </C.FormItem>
              ))}

              <C.Actions>
                <button
                  className="ct-link-text btn-dark cursor-pointer"
                  onClick={() => onNext(1)}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}
          {CurrentStep === 2 && (
            <>
              <C.SurveyFormTitle className="ct-headline  color-dark font-medium ">
                Wie identifizierst du dich?
              </C.SurveyFormTitle>
              {STEP_TWO.map((item, i) => (
                <C.FormItem
                  key={i}
                  active={SelectedStepTwo?.id === item?.id}
                  onClick={() => onItemSelect(2, item)}
                >
                  {item?.title}
                </C.FormItem>
              ))}

              <C.Actions>
                <button className="btn-prev" onClick={() => onPrev(2)}>
                  Zurück
                </button>
                <button
                  className="ct-link-text btn-dark cursor-pointer"
                  onClick={() => onNext(2)}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}
          {CurrentStep === 3 && (
            <>
              <C.SurveyFormTitle className="ct-headline color-dark font-medium ">
                Wie alt bist du?
              </C.SurveyFormTitle>
              {STEP_THREE.map((item, i) => (
                <C.FormItem
                  key={i}
                  active={SelectedStepThree?.id === item?.id}
                  onClick={() => onItemSelect(3, item)}
                >
                  {item?.title}
                </C.FormItem>
              ))}

              <C.Actions>
                <button className="btn-prev" onClick={() => onPrev(3)}>
                  Zurück
                </button>
                <button
                  className="ct-link-text btn-dark cursor-pointer"
                  onClick={() => onNext(3)}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}

          {CurrentStep === 4 && (
            <>
              <C.SurveyFormTitle className="ct-headline color-dark font-medium">
                Wie erreichen wir dich?
              </C.SurveyFormTitle>

              {error ? <p className="error text-center">{error}</p> : ""}
              <br />

              <C.PhoneNumberInput
                type="text"
                placeholder="Vorname"
                value={FirstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <C.PhoneNumberInput
                type="text"
                placeholder="Nachname"
                value={LastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <C.PhoneNumberInput
                type="text"
                placeholder="Kontaktnummer"
                value={PhoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
              <C.EmailInput
                type="email"
                placeholder="E-Mail"
                value={Email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <C.Textarea
                type="text"
                placeholder="Was möchtest du uns noch mitteilen?"
                rows={5}
                value={Message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <br />
              <br />
              <div className="checkbox-group d-flex gap-2">
                <label class="container-checkbox">
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  />
                  <span class="checkmark"></span>
                </label>
                <p className="my-0">
                  Ich habe die{" "}
                  <a
                    className=""
                    href="https://foodiary.app/terms/agb-coaching/"
                    target="_blank"
                  >
                    AGB Foodiary Coaching
                  </a>{" "}
                  und die{" "}
                  <a
                    className=""
                    href="https://foodiary.app/terms/datenschutz/"
                    target="_blank"
                  >
                    Datenschutzbestimmungen
                  </a>{" "}
                  gelesen, verstanden und akzeptiere diese.
                </p>
              </div>
              <C.Actions>
                <button
                  className="btn-prev "
                  disabled={isTrainerLeadSaving}
                  onClick={() => onPrev(4)}
                >
                  Zurück
                </button>
                <button
                  className="ct-link-text  cursor-pointer btn-dark"
                  onClick={() => onNext(4)}
                  disabled={isTrainerLeadSaving}
                >
                  Weiter
                </button>
              </C.Actions>
            </>
          )}

          {CurrentStep === 5 && (
            <>
              <C.SurveyFormTitle className="ct-headline color-dark text-3xl">
                Ich melde mich bei dir.
              </C.SurveyFormTitle>

              <p className="ct-text-block color-paragraph text-lg">
                In den nächsten 48 Stunden werde ich mich für eine
                Terminvereinbarung bei dir melden.
              </p>
              <G.PlanBoxIcons>
                {[
                  "Kennenlernen inkl. Anamnese und persönlichen Ernährungsplan",
                  "Tipps & Tricks für deine einfache Ernährungsumstellung",
                  "Bis zu 100% Kostenrückerstattung der Krankenkasse auf unser präventives Angebot",
                ].map((t) => (
                  <li>
                    <G.PlanBoxIconBox>
                      <img src={GreenTickWhiteBg} alt="Tick" />
                    </G.PlanBoxIconBox>
                    <G.PlanBoxInformation className="ct-text-block text-lg">
                      {t}
                    </G.PlanBoxInformation>
                  </li>
                ))}
              </G.PlanBoxIcons>
              <C.MealPlanContainer>
                <p className="left-title text-base">UNTERSTÜTZ DURCH:</p>
                <C.RestaurauntsBox>
                  <div className="restaurant hover-float">
                    <img src={FoodIconOne} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconTwo} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconThree} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconFour} alt="" />
                  </div>
                  <div className="restaurant hover-float">
                    <img src={FoodIconFive} alt="" />
                  </div>
                </C.RestaurauntsBox>
              </C.MealPlanContainer>

              {/* <C.Actions>
                <button className="ct-link-text foodiary-green-button cursor-pointer">
                  Schließen
                </button>
              </C.Actions> */}
            </>
          )}
        </div>
      </C.SurveyFormCard>
    </C.SurveyForm>
  );
};

export default LeadsSurveyForm;
