import React from "react";

import * as C from "./PreventionCourseSteps.styled";

// Images
import Quiz from "assets/images/knowledge-quiz.png";
import Instructor from "assets/images/instructor.png";
import Material from "assets/images/Learning-material.png";

const PreventionCourseSteps = () => {
  return (
    <C.Wrap>
      <div id="div_block-81-9" className="ct-div-block w-full centered">
        <h3
          id="text_block-82-9"
          className="ct-text-block color-dark text-center uppercase mt-8 font-light"
        >
          PRÄVENTIONSKURS
          <br />
        </h3>
        <h3
          id="span-83-9"
          className="ct-span color-dark  text-center uppercase font-medium"
        >
          GEPRÜFTES WISSEN FÜR NACHHALTIGE GESUNDHEIT 🤘
        </h3>
        <div
          id="div_block-84-9"
          className="ct-div-block w-full grid items-stretch lg-grid-cols-2 gap-8 sm-grid-cols-1 grid-cols-3 mt-20 md-grid-cols-1"
        >
          <div id="div_block-85-9" className="ct-div-block text-left">
            <img
              className="ct-image shadow-md rounded-xl"
              src={Quiz}
              id="image-86-9"
              alt=""
            />
            <div
              id="text_block-87-9"
              className="ct-text-block color-dark text-xl font-medium mt-8 ml-2"
            >
              Wissensquiz
            </div>
            <div
              id="text_block-88-9"
              className="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
            >
              Teste dein Wissen in jeder Lektion und festige dein gelerntes
              Wissen. Nach erfolgreichem Abschluss einer Lektion wird die
              nächste in der kommenden Woche freigeschaltet.
            </div>
          </div>
          <div id="div_block-89-9" className="ct-div-block text-left">
            <img
              id="image-90-9"
              alt=""
              src={Instructor}
              className="ct-image shadow-md rounded-xl"
            />
            <div
              id="text_block-91-9"
              className="ct-text-block color-dark text-xl font-medium mt-8 ml-2"
            >
              Kursleiter
            </div>
            <div
              id="text_block-92-9"
              className="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
            >
              Erhalte eine Rundum-Betreuung durch unseren zertifizierten
              Kursleiter, der innerhalb von 24 Stunden für Rückfragen und
              Unterstützung zur Verfügung steht. Perfekte Unterstützung für
              deinen Erfolg!
            </div>
          </div>
          <div id="div_block-93-9" className="ct-div-block text-left">
            <img
              id="image-94-9"
              alt=""
              src={Material}
              className="ct-image shadow-md rounded-xl"
            />
            <div
              id="text_block-95-9"
              className="ct-text-block color-dark text-xl font-medium mt-8 ml-2"
            >
              Umfangreiches Lernmaterial
            </div>
            <div
              id="text_block-96-9"
              className="ct-text-block text-base font-normal color-paragraph mt-4 ml-2"
            >
              Nutze unser Lernmaterial, um das Wissen aus den Lektionen optimal
              zu vertiefen. Du hast die Möglichkeit, die Handouts einzeln
              herunterzuladen oder direkt auf deinem PC durchzuarbeiten.
            </div>
          </div>
        </div>
      </div>
    </C.Wrap>
  );
};

export default PreventionCourseSteps;
