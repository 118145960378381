import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Divider, Dropdown, Collapse } from "antd";
import * as Styles from "./StickyNavbar.style";

import { menuItems } from "constants/index";
import Logo from "assets/images/Foodiary-Logo.png";
import ArrowCircle from "assets/images/arrow-circle.svg";
import MenuIcon from "assets/images/menu-icon.svg";
import CloseMenuIcon from "assets/images/close-cross.svg";
import ChevronDown from "assets/images/chevron-down.svg";
import BottomImg from "assets/images/menu-img.jpg";

const getFormattedSubLinks = (subLinks, openSurveyModal) => {
  if (!subLinks) return null;

  return subLinks.map((subLink) => {
    if (subLink.type === "divider") {
      return {
        key: subLink.link,
        label: <Styles.DropDivider />,
      };
    }

    if (subLink.type === "outline") {
      if (subLink.onClick === "openSurveyModal") {
        return {
          key: subLink.link,
          label: (
            <Styles.DropSubItemOutline>
              <NavLink
                to="#"
                className="drop-sub-btn"
                onClick={(e) => {
                  e.preventDefault();
                  openSurveyModal();
                }}
              >
                {subLink.name}
              </NavLink>
            </Styles.DropSubItemOutline>
          ),
        };
      }
      return {
        key: subLink.link,
        label: (
          <Styles.DropSubItemOutline>
            <NavLink to={subLink.url} className="drop-sub-link">
              {subLink.name}
            </NavLink>
          </Styles.DropSubItemOutline>
        ),
      };
    }

    return {
      key: subLink.link,
      label: (
        <Styles.DropSubItem>
          <NavLink to={subLink.url} className="drop-sub-link">
            {subLink.name}
          </NavLink>
        </Styles.DropSubItem>
      ),
    };
  });
};
function StickyNavbar({ openSurveyModal }) {
  const [isActiveBg, setIsActiveBg] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleCollapseChange = (keys) => {
    console.log("🚀 ~ handleCollapseChange ~ keys:", keys);
    if (expandedPanels.includes(keys.url)) {
      setExpandedPanels(expandedPanels.filter((key) => key !== keys.url));
    } else {
      setExpandedPanels([...expandedPanels, keys.url]);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", shouldBeSticky);
    return () => window.removeEventListener("scroll", shouldBeSticky);
  }, []);
  console.log(expandedPanels);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [mobileMenuOpen]);

  const shouldBeSticky = () =>
    window.scrollY > 50 && !isActiveBg
      ? setIsActiveBg(true)
      : setIsActiveBg(false);

  return (
    <Styles.RelativeContainer mobileMenuOpen={mobileMenuOpen}>
      <Styles.NavbarContainer
        mobileMenuOpen={mobileMenuOpen}
        isActiveBg={isActiveBg || mobileMenuOpen}
      >
        <div className="new-navbar-content">
          <div className="new-navbar-logo">
            <NavLink to="/">
              <Styles.NavbarLogo className="new-navbar-logo">
                <img src={Logo} alt="" />
              </Styles.NavbarLogo>
            </NavLink>
          </div>

          <div className="new-navbar-actions-desktop">
            <div className="new-navbar-links">
              {menuItems.map((menuItem) => {
                if (!menuItem.subItems)
                  return (
                    <NavLink activeClassName="is-active" to={menuItem.url}>
                      {menuItem.name}
                    </NavLink>
                  );

                return (
                  <Dropdown
                    arrow={menuItem.subItems !== null}
                    menu={{
                      items: getFormattedSubLinks(
                        menuItem.subItems,
                        openSurveyModal
                      ),
                    }}
                    placement="bottom"
                    dropdownRender={(menu) => {
                      return (
                        <Styles.DropdownWrapper>
                          {React.cloneElement(menu)}
                          <Divider />
                        </Styles.DropdownWrapper>
                      );
                    }}
                  >
                    <NavLink activeClassName="is-active" to={menuItem.url}>
                      {menuItem.name}
                    </NavLink>
                  </Dropdown>
                );
              })}
            </div>

            <div className="new-navbar-cta">
              <button
                onClick={openSurveyModal || (() => {})}
                className="btn-dark"
              >
                Erstgespräch vereinbaren
              </button>
              <div className="new-navbar-login">
                <a href="https://my.foodiary.app/">
                  <img src={ArrowCircle} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div className="new-navbar-actions-mobile">
            <div
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="new-navbar-mobile-trigger"
            >
              <a href="#!">
                <img src={mobileMenuOpen ? CloseMenuIcon : MenuIcon} alt="" />
              </a>
            </div>
            {/* <div className="new-navbar-login">
              <a href="https://my.foodiary.app/">
                <img src={ArrowCircle} alt="" />
              </a>
            </div> */}
          </div>
        </div>
        {mobileMenuOpen ? (
          <div className="mobile-menu-content">
            <div className="mobile-menu-links">
              {menuItems.map((menuItem) => {
                if (menuItem.subItems)
                  return (
                    <Styles.CollapseWrapper>
                      <Collapse
                        onChange={() => handleCollapseChange(menuItem)}
                        ghost
                        expandIcon={({ isActive }) => (
                          <Styles.ExpandIcon>
                            <img src={ChevronDown} />
                          </Styles.ExpandIcon>
                        )}
                        expandIconPosition={"end"}
                        items={[
                          {
                            key: menuItem.link,
                            label: menuItem.name,
                            children: (
                              <Styles.CollapseSubMenu>
                                {menuItem.subItems.map((subLink) => {
                                  if (subLink.type === "divider") return;
                                  return (
                                    <NavLink
                                      activeClassName="is-active"
                                      to={subLink.url}
                                    >
                                      {subLink.name}
                                    </NavLink>
                                  );
                                })}
                              </Styles.CollapseSubMenu>
                            ),
                          },
                        ]}
                      />
                    </Styles.CollapseWrapper>
                  );

                return (
                  <NavLink activeClassName="is-active" to={menuItem.url}>
                    {menuItem.name}
                  </NavLink>
                );
              })}
            </div>
            {(!expandedPanels || expandedPanels.length === 0) && (
              <Styles.BottomWrapper>
                <div className="mobile-menu-wrapper">
                  <div className="overview-image-wrapper">
                    <Styles.Title>Kennenlernen</Styles.Title>
                    <Styles.SubTitle>
                      Lerne mich in einem kostenlosen und unverbindlichen
                      Erstgespräch kennen.
                    </Styles.SubTitle>
                    <div className="bottom-img-wrapper">
                      <img className="bottom-img" src={BottomImg} />
                    </div>
                  </div>
                </div>
                <Styles.ButtonWrapper>
                  <button
                    className="ct-link-text hover-float btn-dark lg-w-full"
                    onClick={openSurveyModal}
                  >
                    Erstgespräch vereinbaren
                  </button>
                </Styles.ButtonWrapper>
              </Styles.BottomWrapper>
            )}
          </div>
        ) : (
          ""
        )}
      </Styles.NavbarContainer>
    </Styles.RelativeContainer>
  );
}

export default StickyNavbar;
