import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./TestedKnowledge.style";
import StartTogetherImage from "assets/images/Page3/Foodiary-Coaching-start.svg";

const TestedKnowledge = ({ onLeadFormModal }) => {
  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="Coaching PLUS" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Geprüftes Wissen für deinen langfristigen Erfolg.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Mein digitales Coaching ist perfekt für dich, wenn du dir die
          Grundlagen einer gesunden Ernährung mithilfe meiner digitalen
          Lerninhalte aneignen möchtest. Nach dem Erstgespräch, in dem wir
          deinen Ernährungsplan erstellen, erhältst du 3 Monate Zugriff auf die
          digitale Lernacademy. Dort findest du einen Präventionskurs, eine
          umfangreiche Wissenssammlung mit geprüftem Wissen und tägliche
          Gruppencalls zum Austausch mit anderen Teilnehmern.
        </C.Description>

        <br />

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Der Präventionskurs wird von deiner gesetzlichen Krankenkasse
          bezuschusst und es findet eine Kostenrückerstattung von bis zu 100%
          statt.
        </C.Description>

        <C.Action>
          <div className="mt-10">
            <button className="btn-dark" onClick={() => onLeadFormModal()}>
              Erstgespräch vereinbaren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={StartTogetherImage} alt="together" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default TestedKnowledge;
