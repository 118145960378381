import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./LearningMaterial.styled";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Handout-Prevention-Foodiary.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";

const LearningMaterial = () => {
  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="LERNMATERIAL" margin="0 0 4rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Umfassendes Lernpaket für nachhaltige, gesunde Ernährung
        </C.Title>

        <p className="ct-text-block color-paragraph text-base text-left mt-6">
          In unserem Präventionskurs „Onlinekurs: Mit bewusster Ernährung zu
          mehr Wohlbefinden“ stehen dir nicht nur informative Videos zur
          Verfügung, sondern auch zusätzliches Lernmaterial. Du erhältst
          Handouts, ein Wissensquiz nach jeder Lektion und einen Kursleiter für
          Rückfragen. Zusätzlich begleiten dich wöchentliche E-Mails, um deinen
          Lernprozess zu unterstützen und zu vertiefen.
        </p>

        <C.PlanBoxIcons className="">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Wissensquiz nach jeder Lerneinheit
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Kursleiter für schnelles Feedback innerhalb 24 Stunden.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Umfrangreiches Lernmaterial um dein Wissen zu vertiefen
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
      <C.VisualContent>
        <img src={Coach} alt="Foodiary-Coaching-Bild2" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default LearningMaterial;
