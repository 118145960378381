import styled from "styled-components";

export const RelativeContainer = styled.div`
  position: ${(props) => (props.mobileMenuOpen ? "fixed" : "relative")};
  height: ${(props) => (props.mobileMenuOpen ? "100dvh" : "auto")};
  width: 100%;
  overflow: hidden;
  background: transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%);
  z-index: 999;

  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const NavbarContainer = styled.div`
  position: relative;
  max-width: 151.7rem;
  width: 100%;
  margin-inline: auto;
  padding: 20px 15px;
  border-radius: 15px;
  position: fixed;
  left: calc(50% - 151.7rem / 2);
  top: 20px;
  background: ${(props) => (props.isActiveBg ? "white" : "none")};
  box-shadow: ${(props) =>
    props.isActiveBg ? "0px 5px 10px #142C1F14" : "none"};
  z-index: 900;
  transition: all 100ms ease-in-out;
  @media (max-width: 600px) {
    padding: 10px 20px 5px 20px;
    border-radius: 20px;
  }
  .mobile-menu-wrapper {
    background: transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 10px 0;
    border-radius: 16px;

    p {
      padding: 0px 20px;
    }
    .new-navbar-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      /* height: 450px; */
    }
    .overview-image-wrapper {
      padding: 15px 10px 10px 10px;
      border-radius: 16px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
      .bottom-img-wrapper {
        background: white;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        margin-top: 25px;
      }
      .bottom-img {
        width: 100%;
        height: 210px;
      }
    }
  }
  .mobile-menu-links {
    display: flex;
    flex-direction: column;

    .is-active {
      color: #2ecf96;
    }
    @media (max-width: 600px) {
      .is-active {
        color: #44554c;
      }
    }
    a {
      color: #44554c;
      padding: 10px 0px;
      font-size: 18px;
    }
  }

  .mobile-menu-content {
    background: white;
    padding: 20px 5px;
    .mobile-menu-cta {
      padding: 10px;
    }
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    width: 93%;
    left: 3.5%;
    height: ${(props) => (props.mobileMenuOpen ? "96dvh" : "auto")};
  }

  .new-navbar-actions-mobile {
    display: none;
  }

  .new-navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .new-navbar-cta {
      display: flex;
      align-items: center;
      gap: 20px;

      button {
        padding: 10px 10px;
        font-size: 14px;
      }
    }

    .new-navbar-actions-desktop {
      display: flex;
      align-items: center;
      gap: 100px;

      @media (max-width: 1050px) {
        gap: 30px;
      }

      .new-navbar-links {
        display: flex;
        gap: 60px;
        font-size: 16px;
        font-weight: 600;

        a {
          color: #142c1f;
          padding: 10px 0px;
          position: relative;

          &:after {
            transition: all 300ms ease-in-out;
            background: rgb(68, 85, 76);
            position: absolute;
            content: "";
            height: 3px;
            width: 0px;
            left: calc(50% - 15px);
            bottom: 0;
            border-radius: 5px;
            opacity: 0;
          }

          &:hover {
            &:after {
              opacity: 1;
              width: 30px;
            }
          }
        }

        .is-active {
          &:after {
            background: #2ecf96;
            opacity: 1;
            width: 30px;
          }
        }
      }
    }

    .new-navbar-actions-mobile {
      align-items: center;
      gap: 10px;

      .new-navbar-login,
      .new-navbar-mobile-trigger {
        a {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    @media (max-width: 1000px) {
      .new-navbar-actions-mobile {
        display: flex;
      }

      .new-navbar-actions-desktop {
        display: none;
      }
    }
  }
`;
export const Title = styled.h1`
  font-size: 20px;
  color: #142c1f;
  text-align: center;
  font-weight: 500;
`;
export const SubTitle = styled.p`
  font-size: 14px;
  color: #44554c;
  text-align: center;
  margin: 10px 0px 15px;
`;
export const DropdownWrapper = styled.div`
  min-width: 200px;

  ul {
    padding: 10px !important;

    li {
      padding: 0px !important;

      > * {
        padding: 0px !important;
      }

      &:hover {
        background: none !important;

        a {
          color: #2ecf96;
        }
      }
    }
  }
`;

export const DropSubItem = styled.div`
  padding: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 0;
    height: 80%;
    width: 2px;
    background-color: white;
    transition: background-color 0.3s;
  }

  &:hover::before {
    background-color: #2ecf96;
  }

  a {
    color: #44554c;
  }
`;

export const DropSubItemOutline = styled.div`
  padding: 8px 0px;
  text-align: center;
  margin-top: 5px;
  border: 2px solid #25a08b;
  border-radius: 10px;
  &:hover {
    background: #25a08b;

    a {
      color: white !important;
    }
  }

  a {
    padding: 20px 0px;
    font-weight: bold;
  }
  .drop-sub-btn {
    cursor: pointer;
  }
`;

export const DropDivider = styled.div`
  background: lightgrey;
  height: 1px;
  margin: 10px 0px;
`;

export const CollapseWrapper = styled.div`
  > * {
    > * {
      padding: 10px 0px !important;

      > * {
        padding: 0px !important;
        font-size: 18px !important;
        color: #44554c !important;
      }
    }
  }
`;

export const ExpandIcon = styled.div`
  img {
    width: 15px;
  }
`;

export const CollapseSubMenu = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 16px !important;
    color: #44554c !important;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;

export const NavbarLogo = styled.div`
  img {
    width: 120px;

    @media (max-width: 600px) {
      width: 90px;
    }
  }
`;
export const BottomWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0px 20px;
`;
