import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./NutritionPlanner.style";
import ReactPlayer from "react-player";
import videoSource from "assets/videos/stats-dashboard.mp4";

const NutritionPlanner = ({ onLeadFormModal }) => {
  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.VisualContent>
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
      <C.TextContent>
        <Chip text="APP" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Über 1700 Rezepte begleiten dich
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Die Ernährungsplaner-App ist ein kostenloser Bestandteil meines
          Coaching PLUS.
        </C.Description>

        <br />

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Die Ernährungsplaner-App hilft dir, dein neues Wissen im Alltag
          umzusetzen. Mit einer großen Auswahl an gesunden Rezepten, einer
          dynamischen Einkaufsliste und deinem persönlichen Ernährungsplan ist
          sie der ideale Begleiter für die nächsten 3 Monate.
        </C.Description>

        <C.Action>
          <div className="mt-10">
            <button className="btn-dark" onClick={() => onLeadFormModal()}>
              Erstgespräch vereinbaren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
    </C.Wrap>
  );
};

export default NutritionPlanner;
