import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./nextSteps.styled";

// Images
import NextStepsPng from "assets/images/Foodiary-Zoom.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";

const NextSetps = ({ onLeadFormModal }) => {
  return (
    <C.Wrap>
      <C.TextContent>
        <Chip text="ABLAUF" margin="0 0 3rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Die nächsten Schritte
        </C.Title>
        <C.PlanBoxIcons className="procedure">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Beantworte mir 3 Fragen im Formular, damit ich weiß, wie ich dich
              am besten unterstützen kann.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Ich melde mich innerhalb 48 Stunden bei dir per Telefon. Hier
              finden wir einen passenden Termin für das Erstgespräch.
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Zum vereinbarten Zeitpunkt treffen wir uns im Video-Call, um die
              IST-Analyse zu besprechen und deinen Ernährungsplan zu erstellen.
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>
            Erstgespräch vereinbaren
          </button>
        </C.Action>
      </C.TextContent>

      <C.VisualContent>
        <img src={NextStepsPng} alt="zoom" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default NextSetps;
