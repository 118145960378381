import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./LearnIn12Weeks.style";
import LearnIn12WeeksBanner from "assets/images/learn-in-12-weeks.svg";
import { useHistory } from "react-router-dom";

const LearnIn12Weeks = ({ changeOrder = false, onLeadFormModal }) => {
  const history = useHistory();
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip text="12 Wochen" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Die Ernährung im Fokus: Lerne in
          <br />
          12-Wochen wichtige Grundlagen
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Im Rahmen unseres 12-Wochen Präventionskurses bieten wir dir eine
          strukturierte Lernumgebung mit 12 Einheiten. Jede Einheit beinhaltet
          informative Videos, in denen das Grundlagenwissen zur gesunden
          Ernährung und Prävention vermittelt wird. Die Videos haben eine Dauer
          von 45 bis 60 Minuten und sind praxisbezogen gestaltet, um dir das
          Selbstlernen zu erleichtern.
        </C.Description>

        <C.Action>
          <button
            onClick={() => history.push("/online-kurs")}
            className="btn-dark"
          >
            Kurs starten
          </button>
          <span>Ohne Abo, ohne Kündigung</span>
        </C.Action>
      </div>
      <C.VisualContent>
        <img src={LearnIn12WeeksBanner} alt="" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default LearnIn12Weeks;
