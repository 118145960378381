import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./NutritionWellBeing.style";
import NutritionWellBeingBanner from "assets/images/nutrition-well-being.svg";

const NutritionWellBeing = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={
        changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap"
      }
    >
      <div className="text-content">
        <Chip text="Präventionskurs" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Mit bewusster Ernährung zu mehr Wohlbefinden
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Setze in deinem Alltag auf die Vorteile der Foodiary App. Plane
          einfach deine Ernährung, priorisiere deine Gesundheit und dein
          Wohlbefinden. Es ist jetzt Zeit deine Ernährungsumstellung zu rocken.
        </C.Description>
        <br />
        <C.Description className="ct-text-block text-base mb-4 color-light-grey">
          Smarte Funktionen wie die Wochenplanung, Einkaufsliste und der
          Rezeptfilter helfen dir dabei, deine Ernährung im Alltag step by step
          gesünder zu gestalten.
        </C.Description>
        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>
            Kurs starten
          </button>
          <span>Ohne Abo, ohne Kündigung</span>
        </C.Action>
      </div>
      <C.VisualContent>
        <img src={NutritionWellBeingBanner} alt="" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default NutritionWellBeing;
