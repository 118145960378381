import React from "react";
import { useHistory } from "react-router-dom";
import { PopupButton } from "react-calendly";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./startTogether.styled";
// Images
import StartTogetherImage from "assets/images/Page3/Foodiary-Coaching-start.svg";

const StartTogether = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="Unverbindlich" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Profitiere von meinem Angebot
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Ich unterstütze dich digital oder persönlich auf deinem Weg zu einer
          gesunden, dauerhaften Ernährung. In einem unverbindlichen Erstgespräch
          per Video-Call lernen wir uns kennen und entscheiden gemeinsam, welche
          Betreuung für dich am besten passt.
        </C.Description>

        <C.Action>
          <div className="mt-10">
            <button
              className="btn-dark"
              onClick={() => history.push("/erstgespraech")}
            >
              Mehr erfahren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={StartTogetherImage} alt="together" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default StartTogether;
