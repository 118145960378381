import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IS_LOCAL } from "configs/utils";
import HelpWithSurvey from "components/HelpWithSurvey/HelpWithSurvey";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import FooterNew from "components/FooterNew/FooterNew";
import NewAttitude from "components/NewAttitude/NewAttitude";
import NutritionPlan from "components/NutritionPlan/NutritionPlan";
import RightCoach from "components/RightCoach/RightCoach";
import FirstConsultation from "components/FirstConsultation/FirstConsultation";
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation";
import NextSteps from "components/NextSteps/NextSteps";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
// Styles
import * as C from "./CoachingPlus.style";
import * as G from "styles/General.Styles";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import CoachFromFoodiary from "components/CoachFromFoodiary/CoachFromFoodiary";
import HealthySteps from "components/HealthySteps/HealthySteps";
import ReimburseHealthInsurance from "components/ReimburseHealthInsurance/ReimburseHealthInsurance";
import PreventionCourseSteps from "components/PreventionCourseSteps/PreventionCourseSteps";
import TestedKnowledge from "components/TestedKnowledge/TestedKnowledge";
import LastingSuccessWithKnowledge from "components/LastingSuccessWithKnowledge/LastingSuccessWithKnowledge";
import FullSupport from "components/FullSupport/FullSupport";
import NutritionPlanner from "components/NutritionPlanner/NutritionPlanner";
import ThumbsUpIcon from "assets/images/thumbs-up-icon.svg";
import LetsGoIcon from "assets/images/letsgo-icon.svg";
import PeacIcon from "assets/images/peace-icon.svg";
import FreeCourseSteps from "components/FreeCourseSteps/FreeCourseSteps";
import PlanBoxCoachingPlus from "components/PlanBoxCoachingPlus/PlanBoxCoachingPlus";

const CoachingPlus = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return;

    const coach_website_url = IS_LOCAL
      ? "https://ronja-fci49818.foodiary.app"
      : `${window.location.protocol}//${window.location.host}`;

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: (res) => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar
              openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
            />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip className="font-medium">Coaching PLUS</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Einfacher Start &
                  <br />
                  digitale Wissensvermittlung
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  Mit der digitalen Lernacademy, dem Online-Kurs und
                  Gruppencalls lernst du
                  <br />
                  die Grundlagen einer gesunden Ernährung kennen.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div className="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>
        <SectionWrapper margin="10rem 0" mobileMargin="5rem 0">
          <TestedKnowledge onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0" mobileMargin="5rem 0">
          <LastingSuccessWithKnowledge />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0" mobileMargin="5rem 0">
          <ReimburseHealthInsurance
            onLeadFormModal={() => setOpenLeadModal(true)}
          />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0" mobileMargin="5rem 0">
          <NutritionPlanner onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="5 0 0">
          <FreeCourseSteps
            chipText="eInfacher Start"
            onLeadFormModal={() => setOpenLeadModal(true)}
            list={[
              {
                title: "Erstgespräch vereinbaren",
                description:
                  "Melde dich direkt über das Kontaktformular. Nach Erhalt kontaktiere ich dich umgehend telefonisch, um unser Erstgespräch zu vereinbaren.",
                icon: ThumbsUpIcon,
              },
              {
                title: "Kostenlose IST-Analyse",
                description:
                  "In unserem Erstgespräch erstelle ich dir unverbindlich deinen Ernährungsplan, und wir lernen uns persönlich kennen.",
                icon: PeacIcon,
              },
              {
                title: `Let's go!`,
                description:
                  "Wenn du dich für das Coaching PLUS entscheidest, erhältst du sofort Zugang zur Lernplattform und kannst auf meine Unterstützung zugreifen.",
                icon: LetsGoIcon,
              },
            ]}
          >
            3 Schritte zu deinem Lernerfolg
          </FreeCourseSteps>
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="5rem 0 0">
          <PlanBoxCoachingPlus
            openLeadFormModal={() => setOpenLeadModal(true)}
          />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="5rem 0 0">
          <TestimonialSlider />
        </SectionWrapper>
      </G.Container>

      <SectionWrapper margin="10rem 0 0">
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default CoachingPlus;
