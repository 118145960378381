import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./Personality.styled";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CentralShape from "assets/images/pngs/central-shape.png";

const Personality = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_fm_picture_2
    ? getS3ImageUrl(coach?.cw_fm_picture_2)
    : Coach;

  return (
    <C.Wrap>
      <C.VisualContent>
        {coach?.cw_fm_picture_2 && (
          <C.Image maskImage={CentralShape}>
            <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
          </C.Image>
        )}
        {!coach?.cw_fm_picture_2 && (
          <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        )}
      </C.VisualContent>
      <C.TextContent>
        <Chip text="Besonderheit" margin="0 0 4rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Individuelle Betreuung statt Standard-Programm
        </C.Title>

        <p className="ct-text-block color-paragraph text-base text-left mt-6">
          Mein persönliches Coaching ist völlig individuell und kein
          vorgefertigtes Programm. Du erhältst einen maßgeschneiderten
          Ernährungsplan mit über 1700 leckeren Rezepten und kannst dich während
          der nächsten 3 Monate auf mich als deinen festen Ansprechpartner
          verlassen – für eine nachhaltige und erfolgreiche
          Ernährungsumstellung.
        </p>

        <C.PlanBoxIcons className="">
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Ernährungsplaner-App mit 1700 Rezepten
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Digitale Lernacademy mit Gruppencalls
            </C.PlanBoxInformation>
          </li>
          <li>
            <C.PlanBoxIconBox>
              <img src={GreenTick} alt="Tick" />
            </C.PlanBoxIconBox>
            <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
              Meine persönliche Unterstützung auf deinem Weg
            </C.PlanBoxInformation>
          </li>
        </C.PlanBoxIcons>
      </C.TextContent>
    </C.Wrap>
  );
};

export default Personality;
