import styled from "styled-components";

export const WhyIamYourCoachContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .image-preview-wrapper {
    background: white;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    max-width: 470px; /* Use max-width instead of fixed width */
    height: auto;
    box-shadow: ${(props) =>
      props.shadow ? "0px 10px 15px -3px rgba(0,0,0,0.1)" : "none"};

    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 8px;
      height: auto;
    }

    img {
      display: block; /* Avoid inline spacing issues */
      width: 100%; /* Maintain container responsiveness */
      height: auto; /* Preserve aspect ratio */
      border-radius: inherit; /* Match parent border-radius for consistent look */
      object-fit: cover; /* Ensure image covers the entire area without distortion */
    }
  }

  .content-preview {
    display: flex;
    flex-direction: column;
    /* color: #44554c; */

    @media screen and (max-width: 600px) {
      h3 {
        font-size: 3.2rem !important;
      }
    }

    > * {
      margin-bottom: 20px;
    }

    /* .cta-overview {
      @media screen and (max-width: 600px) {
        display: flex;
        justify-content: flex-start;
      }
    } */
  }
`;
