import React from "react";
import { useHistory } from "react-router-dom";
import * as F from "./intelligentRecipeFilter.style";
import Chip from "components/General/formComponents/chip/Chip";
import ReactPlayer from "react-player";

const IntelligentRecipeFilter = ({ onLeadFormModal }) => {
  const history = useHistory();
  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="Smarter Begleiter" margin="0 0 2rem 0" />
        <F.Title className="ct-text-block color-dark text-left font-medium">
          Ein smarter Begleiter für deine Ernährungsumstellung
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Mit der Ernährungsplaner-App erstellst du ganz einfach deinen eigenen
          Ernährungsplan. Auf diesen kannst du dann ganz einfach im Alltag
          darauf zugreifen und dir passende Rezepte anzeigen lassen. Zudem
          stehen dir natürlich Funktionen wie die Einkaufsliste und das
          Meal-Prep zur Verfügung.
        </F.Description>
        <F.Description className="ct-text-block color-paragraph-alt text-base text-left mt-6">
          Deinen Ernährungsplan kannst du in wenigen Klicks erstellen und dieser
          wird automatisch in die App geladen.
        </F.Description>
        <div>
          <button onClick={onLeadFormModal} className="btn-dark">
            Ernährungsplan erstellen
          </button>
        </div>
      </F.ContentSection>

      <F.VisualContent>
        {/* <img src={FoodiaryErnahrungsberatung} alt="coach" /> */}
        {/*  https://foodiary.app/data/rezepte-auswaehlen-foodiary.mp4 */}
        <ReactPlayer
          url={"https://foodiary.app/data/LP-Foodiary-App-Dashboard-2.mp4"}
          playsinline
          playing
          muted
          loop
        />
        <div className="white-border" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default IntelligentRecipeFilter;
