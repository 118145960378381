import styled from "styled-components";
import { LoginSubHeading } from "pages/login/login.styles";

export const InformationHeading = styled(LoginSubHeading)`
  padding-top: 45px;
`;

export const InformationDescription = styled.div`
  /* font-size: 16px; */
  color: #44554c;
  margin: 0 0 40px 0;
`;

export const Header = styled.header`
  padding-bottom: 100px;
  background: transparent linear-gradient(90deg, #eeeeee 0%, #f8f8f8 100%);

  @media screen and (max-width: 600px) {
    padding-bottom: 80px;
  }
`;

export const HeaderInner = styled.div`
  padding-top: 7.4rem;
`;

export const HeaderContentWrapper = styled.div`
  margin: 16rem 0 0;
  position: relative;

  @media screen and (max-width: 600px) {
    margin-top: 13rem;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
  text-align: center;
  margin-inline: auto;
`;

export const Chip = styled.div`
  border-radius: 42px;
  padding: 12px 24px;
  letter-spacing: 0.32px;
  color: rgb(28, 157, 135);
  text-transform: uppercase;
  background-color: white;
  width: fit-content;
  /* font-weight: bold; */
`;

export const HeaderTitle = styled.div`
  /* font-size: 3rem;
  line-height: 4rem;
  font-weight: 500; */
  color: #142c1f;
  margin: 30px 0;
`;

export const HeaderDescription = styled.div`
  /* font-size: 1.1rem;
  line-height: 1.8rem;
  font-weight: 400; */
  color: #44554c;
`;
