import styled from "styled-components";

export const Wrap = styled.div``;

export const CardsWrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 80px;

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionTitle = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-weight: 300;

  b {
    font-weight: 500;
  }

  @media (max-width: 767.99px) {
    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SectionTitleMobile = styled.h3`
  text-align: center;
  letter-spacing: 0.8px;
  color: #142c1f;
  text-transform: uppercase;
  font-weight: 300;
  b {
    font-weight: 500;
  }

  @media (min-width: 600.99px) {
    display: none;
  }
`;
export const ItemBox = styled.div``;

export const ItemCard = styled.div`
  background: linear-gradient(30deg, #e4f4f1, #ecfaf6);
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 20px #02332a1a;
  padding-top: 70px;
  border-radius: 12px;

  &:hover {
    transform: translateY(-5px);
    background: linear-gradient(30deg, #2ccb94, #1ca088);
  }

  img {
    border-radius: 4px 4px 0 0;
    width: 100%;
    max-width: 80%;
    height: 30rem;
    margin-inline: auto;
    display: block;
  }
`;

export const ItemTitle = styled.div`
  font-size: 2.3rem;
  line-height: 3.5rem;
  margin: 1.88rem 0 0.94rem;
  color: #142c1f;
  font-weight: 500;
`;
export const ItemDescription = styled.div`
  color: #44554c;
`;
