import React from "react";
import {
  getOnboardingStorage,
  setOnboardingStorage,
  GOAL_STEP,
} from "../storage";
import backIcon from "assets/images/back-icon.svg";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  getPreviousOnboardingPage,
  getNextOnboardingPage,
} from "../../../routes/onboardingRoutes";
import * as S from "./SetPassword.styles";
import * as AuthActions from "redux/auth/auth.actions";
import { useDispatch } from "react-redux";

export default function SetPassword({ currentPath }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("app-register");
    const email = localStorage.getItem("userEmail");

    console.log("email", email);
    if (email) {
      setFormData((prev) => ({ ...prev, email }));
    }
    return () => {
      document.body.classList.remove("app-register");
    };
  }, []);
  const validatePassword = (password) => {
    if (password.length < 8) return "Password must be at least 8 characters";
    if (!/[A-Z]/.test(password)) return "Include one uppercase letter";
    if (!/[0-9]/.test(password)) return "Include one number";
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }

    // Validate confirm password match
    if (name === "confirmPassword" || name === "password") {
      const otherValue =
        name === "password" ? formData.confirmPassword : formData.password;

      if (otherValue && value !== otherValue) {
        setErrors((prev) => ({ ...prev, [name]: "Passwords must match" }));
      }
    }
  };
  const changePasswordRedirection = () =>
    window.location.assign("/nutrition/nutritionForm");

  const handleNextPage = () => {
    const newErrors = {};

    // Validate password
    const passwordError = validatePassword(formData.password);
    if (passwordError) newErrors.password = passwordError;

    // Validate password match
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords must match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    onFormSubmit();
  };
  const onFormSubmit = () => {
    dispatch(
      AuthActions.changePasswordRequest({
        formValues: {
          email: formData.email,
          password: formData.password,
          confirm_password: formData.confirmPassword,
        },
        func: changePasswordRedirection,
      })
    );
  };
  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <Link to={getPreviousOnboardingPage(currentPath)}>
            <img className="mt-6" src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="sub-headline mt-3">Passwort setzen</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">
            Weiter
          </span>
        </div>
      </div>
      <div className="step-content">
        <S.SetPasswrordContainer>
          <div className="primary-title mb-2">
            {/* Setze dein Passwort für die App. */}
          </div>
          <div className="accent-subtitle mb-3">
            Mit deiner E-Mail Adresse und dem Passwort kannst du dich später in
            der Ernährungsplaner App anmelden.
          </div>
          <InputContainer
            type="email"
            title="E-Mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <div className="direction-row">
            <InputContainer
              title="Passwort:"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              error={errors.password}
            />
            <InputContainer
              title="Passwort wiederholen:"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={errors.confirmPassword}
            />
          </div>
        </S.SetPasswrordContainer>
      </div>
    </div>
  );
}

function InputContainer({
  title,
  name,
  onChange,
  value,
  type = "text",
  error,
}) {
  return (
    <div className="direction-column">
      <label className="label">{title}</label>
      <S.Input
        disabled={title === "E-Mail" ? true : false}
        type={type}
        autoComplete="off"
        name={name}
        onChange={onChange}
        value={value}
        error={!!error}
      />
      {error && <S.ErrorText>{error}</S.ErrorText>}
    </div>
  );
}
