import React from "react";
import * as F from "./FreeCourseSteps.style";
import StepCard from "components/General/stepCard/StepCard";
import Chip from "components/General/formComponents/chip/Chip";

const FreeCourseSteps = ({ onLeadFormModal, list, children, chipText }) => {
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-text-block color-dark text-left font-medium">
        {chipText ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Chip text={chipText} />
            </div>
          </>
        ) : (
          ""
        )}
        {children}
      </F.SectionTitle>

      <F.CardsWrap>
        {list.map((item) => (
          <StepCard
            title={item?.title}
            description={item?.description}
            icon={item?.icon}
            height="280px"
          />
        ))}
      </F.CardsWrap>

      {onLeadFormModal ? (
        <F.Action>
          <button className="btn-dark" onClick={onLeadFormModal}>
            Ernährungsplan erstellen
          </button>
        </F.Action>
      ) : (
        ""
      )}
    </F.Wrap>
  );
};

export default FreeCourseSteps;
