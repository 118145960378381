import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./PersonalSupport.style";
import { useHistory } from "react-router-dom";
import PersonalSupportImg from "assets/images/personal-support.jpg";
import ReactPlayer from "react-player";
import videoSource from "assets/videos/personal-support.mp4";
const PersonalSupport = ({
  changeOrder = false,
  onLeadFormModal,
  mediaType,
}) => {
  const history = useHistory();

  return (
    <C.Wrap changeOrder={changeOrder} className={"NutritionCoachWrap "}>
      <div className="text-content">
        <Chip text="PERSÖNLICHES COACHING" margin="0 0 20px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium">
          Gemeinsam zu einer nachhaltigen und gesunden Ernährung
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Ich begleite dich im Alltag und unterstütze dich dabei, eine Ernährung
          zu finden, die nicht nur gesund, sondern auch nachhaltig in deinen
          Lebensstil passt. Im Coaching erhältst du individuelle Beratung, die
          auf deine Bedürfnisse abgestimmt ist. Zusätzlich stelle ich dir
          praktische Tools und Ressourcen zur Verfügung, die deinen Alltag
          einfacher machen – damit du deine Ziele langfristig erreichst.
        </C.Description>

        <C.Action>
          <button
            className="btn-dark"
            onClick={() => history.push("/coaching")}
          >
            Mehr zum Coaching
          </button>
        </C.Action>
      </div>
      <C.VisualContent>
        {mediaType == "image" ? (
          <img src={PersonalSupportImg} alt="" />
        ) : (
          <ReactPlayer url={videoSource} playsinline playing muted loop />
        )}
      </C.VisualContent>
    </C.Wrap>
  );
};

export default PersonalSupport;
