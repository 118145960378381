import React from "react";

import * as C from "./VideoCourseAdvantages.styled";

// Images
import Quiz from "assets/images/knowledge-quiz.png";

const VideoCourseAdvantages = () => {
  return (
    <C.Wrap>
      <div
        id="div_block-105-9"
        className="ct-div-block w-full grid grid-cols-2 items-stretch md-grid-cols-1 lg-grid-cols-1"
      >
        <div id="div_block-106-9" className="ct-div-block centered">
          <img
            id="image-107-9"
            alt=""
            src="https://prevention.foodiary.app/wp-content/uploads/2023/05/Ernahrungskurs-Foodiary.png"
            className="ct-image "
            srcset="https://prevention.foodiary.app/wp-content/uploads/2023/05/Ernahrungskurs-Foodiary.png 540w, https://prevention.foodiary.app/wp-content/uploads/2023/05/Ernahrungskurs-Foodiary-300x300.png 300w, https://prevention.foodiary.app/wp-content/uploads/2023/05/Ernahrungskurs-Foodiary-150x150.png 150w"
            sizes="(max-width: 540px) 100vw, 540px"
          />
        </div>
        <div id="div_block-108-9" className="ct-div-block justify-center">
          <div
            id="headline-109-9"
            className="ct-headline py-3 px-6 uppercase text-base rounded-full font-medium color-secondary bg-alt"
          >
            DEINE VORTEILE
          </div>
          <h3
            id="headline-110-9"
            className="ct-headline foodiary-h2 color-dark mt-8 mb-12 font-medium"
          >
            Gesunde Ernährung: Videokurs, Expertenwissen, Unterstützung!
          </h3>
          <div id="div_block-112-9" className="ct-div-block w-full">
            {[
              "Umfassender Videokurs für nachhaltige Ernährung in nur 8 Wochen.",
              "Profitiere von geprüftem Expertenwissen für deine gesunde Ernährung.",
              "Erhalte eine persönliche Betreuung durch erfahrene Ernährungsberater.",
              "Nutze unseren Kurs und erhalte eine Kostenübernahme von deiner Krankenkasse.",
            ].map((item) => (
              <div
                id="div_block-113-9"
                className="ct-div-block oxel_iconlist__row"
              >
                <div id="code_block-114-9" className="ct-code-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5.3rem"
                    height="5.3rem"
                    viewBox="0 0 53 53"
                  >
                    <g
                      id="Gruppe_8254"
                      data-name="Gruppe 8254"
                      transform="translate(-702.831 -3690.77)"
                    >
                      <circle
                        id="Ellipse_529"
                        data-name="Ellipse 529"
                        cx="26.5"
                        cy="26.5"
                        r="26.5"
                        transform="translate(702.831 3690.77)"
                        fill="#e5f5f2"
                      ></circle>
                      <path
                        id="Icon_ionic-ios-checkmark"
                        data-name="Icon ionic-ios-checkmark"
                        d="M30.577,14.021l-1.655-1.7a.356.356,0,0,0-.263-.113h0a.341.341,0,0,0-.263.113L16.923,23.876,12.748,19.7a.364.364,0,0,0-.527,0l-1.674,1.674a.375.375,0,0,0,0,.536l5.266,5.266a1.665,1.665,0,0,0,1.1.536A1.745,1.745,0,0,0,18,27.2h.009L30.587,14.557A.4.4,0,0,0,30.577,14.021Z"
                        transform="translate(708.751 3697.31)"
                        fill="#1c9d87"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div
                  id="text_block-115-9"
                  className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal ml-3"
                >
                  {item}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </C.Wrap>
  );
};

export default VideoCourseAdvantages;
