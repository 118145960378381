import React from "react";
import * as F from "./FeaturesCards.style";
import SmileyOne from "assets/images/hand-shake.svg";
import LernacademHalf from "assets/images/Lernacadem-half.jpg";
import AppHalf from "assets/images/App-half.jpg";
import CoachingFoodiary from "assets/images/Coach-Foodiary.png";
import Chip from "components/General/formComponents/chip/Chip";
import ScollDown from "assets/images/scroll-down.svg";

const FeaturesCards = () => {
  const Cards = [
    {
      image: CoachingFoodiary,
      title: "Persönlicher Ernährungscoach",
      description:
        "Ich unterstütze dich, dein gewünschtes Zielgewicht zu erreichen und eine dauerhafte Ernährungsumstellung zu verwirklichen.",
    },
    {
      image: LernacademHalf,
      title: "Ernährungsplaner-App",
      description:
        "Inklusive Foodiary PRO mit über 1700 Rezepten, deinem persönlichen Ernährungsplan und einer dynamischen Einkaufsliste.",
    },
    {
      image: AppHalf,
      title: "Digitale Lernacademy",
      description:
        "Du lernst die Grundlagen einer dauerhaften und ausgewogenen Ernährung und wirst dabei persönlich von mir begleitet.",
    },
  ];

  return (
    <F.Wrap>
      <F.ScrollDownImagewrap>
        <F.ScrollDownImage src={ScollDown} />
      </F.ScrollDownImagewrap>
      <F.TopContent>
        <Chip className="title" text="ERNÄHRUNG" />
        <F.Title className="ct-headline foodiary-h2 color-dark uppercase mt-10">
          Mein Coaching
          <br />
          <F.Title className="ct-headline foodiary-h2 color-dark uppercase mt-3">
            <b>Schritt für Schritt zu deinem gesunden</b>
          </F.Title>
        </F.Title>
      </F.TopContent>
      <F.CardsWrap>
        {Cards.map((item, i) => (
          <F.CardContainer key={i}>
            <F.Card>
              <div className="image-wrapper">
                <img src={item?.image} alt="pro" />
              </div>
              <F.ContentWrapper>
                <F.CardTitle className="font-medium color-dark text-center">
                  {item?.title}
                </F.CardTitle>
                <F.CardDescription>{item.description}</F.CardDescription>
              </F.ContentWrapper>
            </F.Card>
            <F.PlusIconWrapper className="plus-icon-wrapper">
              <F.PlusIcon />
            </F.PlusIconWrapper>
          </F.CardContainer>
        ))}
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default FeaturesCards;
