import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./PlannerApp.style";
import PlannerAppBanner from "assets/images/planner-app.svg";
import { useHistory } from "react-router-dom";
import videoSource from "assets/videos/stats-dashboard.mp4";
import ReactPlayer from "react-player";

const PlannerApp = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="APP" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark font-medium ">
          Verwende die Ernährungsplaner - App für deinen Alltag.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Setze in deinem Alltag auf die Vorteile der Foodiary App. Plane
          einfach deine Ernährung, priorisiere deine Gesundheit und dein
          Wohlbefinden. Es ist jetzt Zeit deine Ernährungsumstellung zu rocken.
        </C.Description>

        <br />

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Smarte Funktionen wie die Wochenplanung, Einkaufsliste und der
          Rezeptfilter helfen dir dabei, deine Ernährung im Alltag step by step
          gesünder zu gestalten.
        </C.Description>

        <C.Action>
          <div className="mt-10">
            <button className="btn-dark" onClick={() => history.push("/app")}>
              Mehr erfahren
            </button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <ReactPlayer url={videoSource} playsinline playing muted loop />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default PlannerApp;
