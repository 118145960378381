import React, { useState } from "react";
import * as C from "./HealthInsuranceCompanies.styled";

// Images
import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
import FoodIconTwo from "assets/images/App/aok.svg";
import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";

const HealthInsuranceCompanies = ({ title, subtitle, hideHeader }) => {
  const header = hideHeader ? (
    ""
  ) : (
    <C.Title className="ct-headline foodiary-h2 color-dark uppercase">
      {title}
      <br />
      <b>{subtitle}</b>
    </C.Title>
  );

  return (
    <C.MealPlanContainer>
      {header}

      <p className="left-title">unterstützt durch:</p>
      <C.RestaurauntsBox>
        <div className="restaurant">
          <img src={FoodIconOne} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconTwo} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconThree} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconFour} alt="" />
        </div>
        <div className="restaurant">
          <img src={FoodIconFive} alt="" />
        </div>
      </C.RestaurauntsBox>
    </C.MealPlanContainer>
  );
};

export default HealthInsuranceCompanies;
