import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";

import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import PreventionCourse from "components/PreventionCourse/PreventionCourse";
import ConsciousNutritionCourse from "components/ConsciousNutritionCourse/ConsciousNutritionCourse";
import InsuranceCompanies from "components/InsuranceCompanies/InsuranceCompanies";
import LearningMaterial from "components/LearningMaterial/LearningMaterial";
import PreventionCourseSteps from "components/PreventionCourseSteps/PreventionCourseSteps";
import VideoCourseAdvantages from "components/VideoCourseAdvantages/VideoCourseAdvantages";
import FreeCourseSteps from "components/FreeCourseSteps/FreeCourseSteps";
import MaximumReimbursement from "components/MaximumReimbursement/MaximumReimbursement";
import ReimbursementCalculation from "components/ReimbursementCalculation/ReimbursementCalculation";
import hook from "assets/images/hook.svg";

import ThumbsUpIcon from "assets/images/thumbs-up-icon.svg";
import LetsGoIcon from "assets/images/letsgo-icon.svg";
import PeacIcon from "assets/images/peace-icon.svg";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./OnlineKurs.styles";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";

import { IS_LOCAL } from "configs/utils";
import NutritionWellBeing from "components/NutritionWellBeing/NutritionWellBeing";
import LearnIn12Weeks from "components/LearnIn12Weeks/LearnIn12Weeks";

const OnlineKurs = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return;

    const coach_website_url = IS_LOCAL
      ? "https://ronja-fci49818.foodiary.app"
      : `${window.location.protocol}//${window.location.host}`;

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar
              openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
            />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip className="font-medium">Online Kurs</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Grundlagen der
                  <br />
                  gesunden Ernährung
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  Im Online-Kurs lernst du, wie du mit Kalorien, Nährstoffen und
                  Vitaminen eine
                  <br />
                  gesunde Ernährung dauerhaft umsetzt.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div className="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>
        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <NutritionWellBeing onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <InsuranceCompanies />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <LearnIn12Weeks changeOrder />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <LearningMaterial />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <PreventionCourseSteps />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <VideoCourseAdvantages />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <FreeCourseSteps
            list={[
              {
                title: "Anmeldung in wenigen Schritten",
                description:
                  "Melde dich für unseren Präventionskurs an und übernehme zuerst die Kosten.",
                icon: ThumbsUpIcon,
              },
              {
                title: "12 Wochen Ernährungskurs",
                description:
                  "Lerne alle wichtigen Grundlagen einer praxisorientierter und gesunden Ernährung.",
                icon: PeacIcon,
              },
              {
                title: "Kostenerstattung sichern",
                description:
                  "Mit dem Abschlusszertifikat unseres Kurses kannst du bei deiner Krankenkasse eine Kostenerstattung von bis zu 100% beantragen.",
                icon: LetsGoIcon,
              },
            ]}
          >
            In 3 Schritten zum kostenfreien Kurs
          </FreeCourseSteps>
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <MaximumReimbursement
            openLeadFormModal={() => setOpenLeadModal(true)}
          />
        </SectionWrapper>

        <SectionWrapper margin="15rem 0 -5rem" mobileMargin="10rem 0 0">
          <ReimbursementCalculation />
        </SectionWrapper>

        {/* <SectionWrapper margin="15rem 0 0" mobileMargin="10rem 0 0">
          <PreventionCourse />
        </SectionWrapper> */}
      </G.Container>

      <SectionWrapper margin="10rem 0 0" mobileMargin="10rem 0 0">
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>
      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default OnlineKurs;
